// window.$ = window.jQuery = require('jquery');

import 'lazysizes';
import './plugins/mobileMenu';

// import './plugins/hash';

const _dir = $('html').attr('dir');
const _isRtl = (_dir === 'rtl');

((($) => {


        const header = $("#header:not('.no-sticky')");


        function stickyMenu() {
            const headerHeight = $('.header:not(.no-sticky)').height();

            if ($(window).scrollTop() > headerHeight) {
                header.addClass("sticky");
            } else {
                header.removeClass("sticky");
            }
        }

        stickyMenu();

        const UpBtn = $('#upBtn');

        function toTop() {
            if ($(window).scrollTop() > 300) {
                UpBtn.addClass('show');
            } else {
                UpBtn.removeClass('show');
            }
        }

        UpBtn.on('click', function (e) {
            e.preventDefault();
            $('html, body').stop().animate({scrollTop: 0}, 300);
        });

        $(window).on('scroll', function () {
            toTop();
            stickyMenu();
        });

        toTop();

        if ($(window).width() < 768) {
            $('.footer-item.close-mobile .footer-item--title').on('click', function () {
                const _item = $(this);
                const _content = _item.closest('.footer-item').find('.footer-item--content');
                if (!_item.hasClass('open')) {
                    _item.addClass('open');
                    _content.stop().slideDown();
                } else {
                    _item.removeClass('open');
                    _content.stop().slideUp();
                }
            });
        }

        $('ul').each(function (index, list) {
            const _ul = $(list);
            if (_ul.css('list-style-type') == 'square') {
                _ul.addClass('list-square');
            }
        })

        $('.social-menu-btn').on('click', function () {
            const btn = $(this);
            const menu = btn.parent().find('.fixed-social-menu-list');
            if (!btn.hasClass('open')) {
                btn.addClass('open');
                menu.addClass('open');
            } else {
                btn.removeClass('open');
                menu.removeClass('open');
            }
        });

        $(document).on('click', function (e) {
            if (!($(e.target).closest('.social-menu').length > 0)) {
                const btn = $('.social-menu-btn');
                const menu = btn.parent().find('.fixed-social-menu-list');
                if (btn.hasClass('open')) {
                    btn.removeClass('open');
                    menu.removeClass('open');
                }
            }
        });

        if ($(window).width() > 991) {
            $('.has-dropdown').hover(function () {
                const _link = $(this);
                $(`#${_link.attr('data-id')}`).stop().slideDown();
            }, function () {
                const _link = $(this);
                $(`#${_link.attr('data-id')}`).stop().slideUp();
            });
        }
    })(jQuery));

document.addEventListener('lazybeforeunveil', function (e) {
    var bg = e.target.getAttribute('data-bg');
    if (bg) {
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

$("form#dataForm").submit(function (e) {
    e.preventDefault();

    BtnLoading();

    $('.alert-danger').html("");
    $('.alert-danger').fadeOut();


    var formData = new FormData(this);
    const _url = $(this).attr('action');
    const _method = $(this).attr('method');
    if ($(this).validate().form()) {

        $.ajax({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: _url,
            method: _method,
            data: formData,
            cache: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            async: false,
        }).done((response) => {
            if (response.success) {
                setTimeout(
                    function()
                    {
                        $('.form-container').html(response.msg).fadeIn(400);
                    }, 3000);
                BtnReset();
            } else {
                BtnReset();
                return false;

            }
        }).fail((response) => {
            let errors = response.responseJSON.errors;
            $.each(errors, function (key, value) {
                console.log('value', value);
                $('.alert-danger').fadeIn();
                $('.alert-danger').append('<p>' + value + '</p>');
            });
            BtnReset();
            return false;
        });
    }
});

function showLoading() {
    $("#loading").show();
}//end validate function


function BtnLoading() {
    console.log('BtnLoading');
    let $elmId = '#submitBtn';
    $($elmId).attr("data-original-text", $($elmId).html());
    $($elmId).prop("disabled", true);
    $($elmId).html('<i class="fa fa-spinner fa-spin"></i>');
}

function BtnReset() {
    setTimeout(function () {
        let $elmId = '#submitBtn';
        $($elmId).prop("disabled", false);
        $($elmId).html($($elmId).attr("data-original-text"));
    }, 1000);
}

$(".rating input:radio").attr("checked", false);
$('.rating input').click(function () {
    let id = $(this).parent().parent().attr('id');
    $("#" + id + " span ").removeClass('checked');
    $(this).parent().addClass('checked');
});

$('input:radio').change(function () {
    var userRating = this.value;
    console.log(userRating);
});