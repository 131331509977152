const _dir = $('html').attr('dir');
const _isRtl = (_dir === 'rtl');
(
    ($) => {
        $(document).ready(function () {
                const body = $('body');
                const wrapper = $('#menu-list').clone();
                wrapper.removeClass('navbar-collapse');
                wrapper.attr('id', 'menu-list-new');
                const mainUl = wrapper.find('.main-menu-list');
                let MenuCreated = true;

                const closeBtn = $('<button class="mobile-close-btn"><i class="icon-close"></i></button>');

                function mobileMenuFun() {
                    if ($(window).width() <= 991) {
                        let _icon = 'icon-arrow-right';
                        let _back_icon = 'icon-arrow-left';

                        if (_isRtl) {
                            _icon = 'icon-arrow-left';
                            _back_icon = 'icon-arrow-right';
                        }

                        if (body.find('.main-menu-list--mobile').length) {
                            return;
                        }
                        body.append(wrapper);

                        if (MenuCreated) {
                            wrapper.prepend(closeBtn);
                            wrapper.addClass('main-menu-list--mobile');

                            mainUl.attr('id', IdGenerator());
                            const menu = wrapper.find('.main-menu-list > li');
                            menu.each(function (index, child) {
                                if ($(child).children(".sub-menu").length) {
                                    const id = IdGenerator();
                                    $(child).find('> .menu-link').attr('data-target', '#' + id);
                                    const ulChild = $(child).children('.sub-menu');
                                    const back = $(`<div class="sub-menu-item"><button class="back"><i class="${_back_icon}"></i><div class="ul-title"></div></button></div>`);
                                    const _title = $(child).find('> .menu-link .title').html();
                                    back.find('.ul-title').text(_title);
                                    ulChild.prepend(back);
                                    $(ulChild).addClass('main-menu-list').attr('id', id);
                                    wrapper.append(ulChild);
                                    $(child).find('> .menu-link').addClass('has-dropdown').append(`<i class="${_icon}"></i>`)
                                }
                            });
                            backAttr();
                            MenuCreated = false;
                        }
                    } else {
                        $('body').find(wrapper).remove();
                    }

                    $(wrapper).find('.menu-link').on('click', function (e) {
                        const _tab = $(this);
                        if (_tab.hasClass('has-dropdown')) {
                            e.preventDefault();
                        }
                        $(wrapper).find('.main-menu-list').removeClass('open');
                        const el = $(_tab.attr('data-target'));
                        el.addClass('open');
                    });

                    $(wrapper).find('.back').on('click', function () {
                        const _tab = $(this);
                        $(wrapper).find('.main-menu-list').removeClass('open');
                        const id = _tab.attr('data-target');
                        const el = $('[id="' + id + '"]');
                        el.addClass('open');
                    });
                }

                function backAttr() {
                    const back = wrapper.find('.back');
                    back.each(function (index, el) {
                        const id = $(el).parents('.sub-menu').attr('id');
                        const parentId = $(wrapper).find('[data-target="#' + id + '"]').closest('[id]').attr('id');
                        $(el).attr('data-target', parentId);
                    });
                }

                const mobileBtn = $('.burger');
                mobileBtn.on('click', function () {
                    if (!$(mobileBtn).hasClass('open')) {
                        $(mobileBtn).addClass('open');
                        wrapper.addClass('open');
                        mainUl.addClass('open');
                        $('body').css('overflow', 'hidden');
                    } else {
                        $(mobileBtn).removeClass('open');
                        wrapper.removeClass('open');
                        $('.main-menu-list').removeClass('open');
                        $('body').removeAttr('style');
                    }
                });

                body.on('click', '.mobile-close-btn', function () {
                    $(mobileBtn).removeClass('open');
                    wrapper.removeClass('open');
                    $('.main-menu-list').removeClass('open');
                    $('body').removeAttr('style');
                });

                /**
                 * @return {string}
                 */
                function IdGenerator() {
                    return (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);
                }

                mobileMenuFun();

                $(window).on('resize', function () {
                    mobileMenuFun();
                });

                // mini mobile menu
                if ($(window).width() <= 575) {
                    const _anchors = $('#sub-menu-list li .list-item');
                    _anchors.removeClass('list-item');
                    _anchors.addClass('sub-list-item-icon');
                    $('.main-menu-list--mobile .navbar-nav').append($('#sub-menu-list li'));
                }
            }
        );
    }
)(jQuery);
