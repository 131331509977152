// window.$ = window.jQuery = require('jquery');

import './shared';

import 'owl.carousel/dist/owl.carousel.min';
import 'owl.carousel/dist/assets/owl.carousel.min.css';
import 'owl.carousel/dist/assets/owl.theme.default.min.css';

import '../styles/home_index.css';

const _dir = $('html').attr('dir');
const _isRtl = (_dir === 'rtl');

((
    ($) => {

        $('.count').each(function () {
            $(this).prop('counter', 0).animate({
                counter: $(this).text()
            }, {
                duration: 8000,
                easing: 'swing',
                step: function (now) {
                    $(this).text(Math.ceil(now));
                }
            });
        });


        $('input[type=radio][name=experience]').change(function () {
            $('.experience-list').find('.face-icon').removeClass('active');
            $('#' + this.value + '-face').addClass('active');
            var experience = this.value
            const formData = new FormData();
            formData.append('rating', experience);

            $.ajax({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                url: '/rating',
                method: 'post',
                data: formData,
                cache: false,
                dataType: 'json',
                processData: false,
                contentType: false,
                async: false,
            }).done((response) => {
                if (response.success) {

                }
            }).fail((response) => {

            });
        });


        $(".owl-main").owlCarousel({
            rtl: _isRtl,
            margin: 0,
            items: 1,
            dots: true,
            autoplay: true,
            loop: true,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoplayTimeout: 5000,
            onInitialized: function (event) {
                const element = event.target;
                $(element).closest('.loading').removeClass('loading');
            }, onChanged: function (event) {
                const element = event.target;
                const owlItem = $(element).find('.owl-item');
                owlItem.eq(event.item.index).find('.item1-content').addClass('active');
            }
        });
        $(".owl-news").owlCarousel({
            rtl: _isRtl,
            margin: 50,
            items: 4,
            dots: false,
            nav: true,
            autoplay: false,
            loop: false,
            autoplayTimeout: 5000,
            responsive: {
                0: {
                    margin: 0,
                    items: 1,
                },
                576: {
                    margin: 30,
                    items: 2,
                },
                768: {
                    margin: 30,
                    items: 3,
                },
                992: {
                    margin: 30,
                    items: 4,
                },
                1200: {
                    margin: 50,
                    items: 4,
                }
            },
            onInitialized: function (event) {
                const element = event.target;
                $(element).closest('.loading').removeClass('loading');
            }
        });

        const offsetPadding = () => {
            const offset = $('.container').first().offset().left + 20;
            if (_isRtl) {
                $('.offset-right').css('padding-left', offset);
                $('.offset-left').css('padding-right', offset);
            } else {
                $('.offset-right').css('padding-right', offset);
                $('.offset-left').css('padding-left', offset);
            }
        }
        offsetPadding();

        $(window).on('resize', function () {
            offsetPadding();
        });

        let targets = $('.js-intersect');

        if (targets.length) {
            let options = {
                root: null,
                rootMargin: '0px',
                threshold: 0.5
            };

            let observer = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        if (!$(entry.target).hasClass('activated')) {
                            $(entry.target).addClass('activated');
                            const animationClass = $(entry.target).attr('data-animation');
                            $(entry.target).addClass(animationClass + ' animate__animated animate__slow activated');
                        }
                    }
                });
            }, options);

            targets.each(target => {
                observer.observe(targets[target]);
            });
        }

        function item11() {
            const _item11 = $('.item11--item-wrapper');
            const _item11_1 = _item11.eq(0);
            const _item11_2 = _item11.eq(1);
            const _item11_3 = _item11.eq(2);

            if ($(window).width() > 850) {
                _item11_1.css({
                    'width': 'calc(33.3333% + 300px)',
                });
                _item11_2.css({
                    'width': '33.3333%',
                });
                _item11_3.css({
                    'width': 'calc(33.3333% + 300px)',
                });

                _item11_1.hover(function () {
                    _item11_1.addClass('active');
                    _item11_1.css('width', '50%');

                    _item11_2.addClass('small').css({
                        'width': '25%',
                    });
                    _item11_3.addClass('small').css({
                        'width': 'calc(25% + 300px)',
                    });
                }, function () {
                    _item11.removeClass('active small');

                    _item11_1.css({
                        'width': 'calc(33.3333% + 300px)',
                    });
                    _item11_2.css({
                        'width': '33.3333%',
                    });
                    _item11_3.css({
                        'width': 'calc(33.3333% + 300px)',
                    });
                });
                _item11_2.hover(function () {
                    _item11_2.addClass('active');
                    _item11_2.css('width', '50%');

                    _item11_1.addClass('small').css({
                        'width': 'calc(25% + 300px)',
                    });
                    _item11_3.addClass('small').css({
                        'width': 'calc(25% + 300px)',
                    });
                }, function () {
                    _item11.removeClass('active small');

                    _item11_1.css({
                        'width': 'calc(33.3333% + 300px)',
                    });
                    _item11_2.css({
                        'width': '33.3333%',
                    });
                    _item11_3.css({
                        'width': 'calc(33.3333% + 300px)',
                    });
                });
                _item11_3.hover(function () {
                    _item11_3.addClass('active');
                    _item11_3.css('width', '50%');

                    _item11_2.addClass('small').css({
                        'width': '25%',
                    });
                    _item11_1.addClass('small').css({
                        'width': 'calc(25% + 300px)',
                    });
                }, function () {
                    _item11.removeClass('active small');

                    _item11_1.css({
                        'width': 'calc(33.3333% + 300px)',
                    });
                    _item11_2.css({
                        'width': '33.3333%',
                    });
                    _item11_3.css({
                        'width': 'calc(33.3333% + 300px)',
                    });
                });
            } else {
                $('.item11-items').css({
                    'width': '100%',
                });
                _item11_1.css({
                    'width': '100%',
                });
                _item11_2.css({
                    'width': '100%',
                });
                _item11_3.css({
                    'width': '100%',
                });

                _item11_1.hover(function () {
                    _item11_1.addClass('active');
                }, function () {
                    _item11.removeClass('active small');
                });
                _item11_2.hover(function () {
                    _item11_2.addClass('active');
                }, function () {
                    _item11.removeClass('active small');
                });
                _item11_3.hover(function () {
                    _item11_3.addClass('active');
                }, function () {
                    _item11.removeClass('active small');
                });
            }
        }

        item11();

        $(window).resize(function () {
            item11();
        });
    }
)
(jQuery));
